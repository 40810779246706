<template>
    <div class="member-service">
        <div class="container">
            <!-- 面包屑导航 -->
            <div class="member-service-breadcrumb">
                <!-- !!使用插槽 -->
                <router-link to="/lensman/">
                    摄影师
                </router-link>
                <span>/</span>
                <!-- !!此处对接接口后需要改变 -->
                <a href="javascript:;"
                    @click="$router.go(-1)">
                    {{ownName}}的个人页面
                </a>
                <span>/</span>
                <a href="javascript:;"
                    class="member-service-breadcrumb-last">
                    {{serviceDetail.name}}
                </a>
            </div>
            <!-- 主体内容部分 -->
            <div class="service-content">
                <!-- 左边 -->
                <div class="content-lf">
                    <!-- 标题 -->
                    <p class="lf-title">
                        {{serviceDetail.name}}
                    </p>
                    <!-- 副标题 -->
                    <p class="lf-subtitle">
                        服务内容：{{serviceDetail.sketch}}
                    </p>
                    <!-- 图片 -->
                    <div class="lf-pic"
                        v-for="(item, index) in serviceDetail.detail"
                        :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                <!-- 右边 -->
                <div class="content-rt">
                    <service-rt
                        :detail="serviceDetail"
                        :own="serviceDetail.owner"
                        @handle-click="handleSubmit">
                        <template #about v-if="serviceDetail.owner">
                            <service-related
                                v-for="(item, index) in serviceDetail.owner.other_goods"
                                :key="index"
                                :item="item">
                            </service-related>
                        </template>
                    </service-rt>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";

    export default {
        computed: {
            ...mapState({
                user_id: state => state.user.user_id,
                serviceDetail: state => state.goodsDetail.serviceDetail
            }),
            ownName() {
                let own = this.serviceDetail.owner;
                return own? own.name: '';
            }
        },
        components: {
            serviceRelated: () => import("@/components/memberServiceRelated"),
            serviceRt: () => import("@/components/memberServiceRt")
        },
        methods: {
            ...mapMutations([
                'setMemberServiceDetail',
                'handleLoading'
            ]),
            ...mapActions([
                'getMemberServiceDetail',
                'handleAddOrder'
            ]),
            handleSubmit(form) {
                let url = 'ushop/order',
                    params = this.$qs.stringify({
                        userID: this.user_id,
                        goodsID: this.serviceDetail.id,
                        need_dresser: form.need? '1': '0',
                        book_date: form.startDate,
                        book_time: form.startTime
                    });

                if(!this.user_id){
                    this.$message({
                        showClose: true,
                        message: '您还未登录，请先登录',
                        type: 'warning'
                    })
                    return ;
                } else if(this.user_id == this.serviceDetail.owner.own_user_id) {
                    this.$message({
                      showClose: true,
                      message: '自己的商品不可下单哦',
                      type: 'warning'
                    })
                    return ;
                }

                this.handleLoading(true);

                this.handleAddOrder({
                    url,
                    params
                }).then(res => {
                    let data = res.data;

                    this.$message({
                        showClose: true,
                        message: data.message,
                        type: data.code == 200? 'success': 'error'
                    })

                    setTimeout(() => {
                        this.$router.push({
                            name: 'memberPay',
                            query: {
                                id: data.result.orderID
                            }
                        })
                    }, 2000)
                }).catch(() => {
                }).finally(() => {
                    this.handleLoading(false);
                })
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                let userID = to.query.userID,
                    goodsID = to.query.id;

                vm.getMemberServiceDetail({
                    userID,
                    goodsID
                })
            });
        },
        beforeRouteLeave (to, from, next) {
            this.setMemberServiceDetail({})
            next();
        }
    }
</script>

<style scoped>
.member-service{
    padding: 30px 0;
    background-color: #fafafa;
}

/* 面包屑导航 */
.member-service-breadcrumb{
    margin-bottom: 20px;
}
.member-service-breadcrumb > span{
    margin: 0 5px;
    color: #C0C4CC;
}
.member-service-breadcrumb > a{
    color: #8d9afc;
}
.member-service-breadcrumb > .member-service-breadcrumb-last{
    cursor: default;
    color: #909399;
}

/* 主体内容部分 */
.service-content {
    display: flex;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
}
.content-lf {
    flex: 1;
}
.lf-title {
    margin-bottom: 20px;
    font-size: 26px;
}
.lf-subtitle {
    margin-bottom: 20px;
    color: #909399
}
.lf-pic > img {
    width: 100%;
}
.content-rt {
    flex-shrink: 0;
    width: 360px;
    margin-left: 20px;
}
</style>
